import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 768px; 
  ${({ questionColor }) =>
    questionColor ? `& .question { color: ${questionColor};}` : ``}
  ${({ answerColor }) =>
    answerColor ? `& .answer { color: ${answerColor};}` : ``}
  ${({ borderColor }) =>
    borderColor
      ? `& .faqs-item { border-bottom: 1px solid ${borderColor};}`
      : ``}
`;

export const UList = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

export const UListItem = styled.div`
  border-bottom: 1px solid #000;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
`;

export const Question = styled.div`
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  display: block;
  padding-right: 20px;
`;

export const Answer = styled.div`
  font-size: 16px;
  font-weight: 400;
  &.active {
    display: block;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOutOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const Icon = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 0;
  &:before,
  &:after {
    position: absolute;
    content: '';
    transition: transform 0.2s ease-in-out;
    background-color: ${props => props.arrowColor};
    opacity: 1;
  }
  &:before {
    width: 2px;
    height: 12px;
    right: 5px;
    top: 4px;
  }
  &:after {
    width: 12px;
    height: 2px;
    right: 0px;
    top: 9px;
  }
  &.enter-done {
    &:before,
    &:after {
      transform: rotate(90deg);
    }
    :after {
      transform: rotate(90deg);
      opacity: 0;
    }
  }
`;
